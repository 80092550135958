import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import TeamSelect from "./TeamSelect";
import HyderabadCreateNew from "./pages/HyderabadCreateNew";
import HyderabadHistory from "./pages/HyderabadHistory";
import HyderabadIntroduction from "./pages/HyderabadIntroduction";
import HyderabadViewResult from "./pages/HyderabadViewResult";
import LXWrap from "./pages/LXWrap";
import LoginForm from "./LoginForm";
import HyderabadUploadRejectCriteria from "./pages/HyderabadUploadRejectCriteria";
import BangaloreSubSelect from "./pages/BangaloreSubSelect";
import BangaloreMeltwater from "./pages/BangaloreMeltwater";
import HyderabadSubSelect from "./pages/HyderabadSubSelect";
import HyderabadPharmaIntroduction from "./pages/HyderabadPharmaIntroduction";
import HyderabadPharmaUpload from "./pages/HyderabadPharmaUpload";
import HyderabadPharmaHistory from "./pages/HyderabadPharmaHistory";
import HyderabadPharmaCrossCheck from "./pages/HyderabadPharmaCrossCheck";
import HyderabadPharmaStopProcess from "./pages/HyderabadPharmaStopProcess";
import BangaloreDetails from "./pages/BangaloreDetails";
import BangaloreIsentiaMain from "./pages/BangaloreIsentiaMain";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LXWrap />,
  },
  {
    path: "/team-select",
    element: <TeamSelect />,
  },
  {
    path: "/login",
    element: <LoginForm />,
  },
  {
    path: "/blr",
    element: <BangaloreSubSelect />,
  },
  {
    path: "/hyd",
    element: <HyderabadSubSelect />,
  },
  {
    path: "/blr/isentia",
    element: <BangaloreIsentiaMain />,
  },
  {
    path: "/blr/isentia/:detailId",
    element: <BangaloreDetails />,
  },
  {
    path: "/blr/meltwater",
    element: <BangaloreMeltwater />,
  },
  {
    path: "/team-select",
    element: <TeamSelect />,
  },
  {
    path: "/hyd/hyd-meltwater",
    element: <HyderabadIntroduction />,
  },
  {
    path: "/hyd/hyd-meltwater/history",
    element: <HyderabadHistory />,
  },
  {
    path: "/hyd/hyd-meltwater/view-result/:rxid",
    element: <HyderabadViewResult />,
  },
  {
    path: "/hyd/hyd-meltwater/create-new",
    element: <HyderabadCreateNew />,
  },

  {
    path: "/hyd/hyd-meltwater/upload-reject-criteria",
    element: <HyderabadUploadRejectCriteria />,
  },
  {
    path: "/hyd/hyd-pharma",
    element: <HyderabadPharmaIntroduction />,
  },
  {
    path: "/hyd/hyd-pharma/upload",
    element: <HyderabadPharmaUpload />,
  },
  {
    path: "/hyd/hyd-pharma/history",
    element: <HyderabadPharmaHistory />,
  },
  {
    path: "/hyd/hyd-pharma/cross-check/:rxid",
    element: <HyderabadPharmaCrossCheck />,
  },
  {
    path: "/hyd/hyd-pharma/stop-process/:rxid",
    element: <HyderabadPharmaStopProcess />,
  },
  {
    path: "/",
    errorElement: <div>404</div>,
  },
]);

ReactDOM.createRoot(document.querySelector("#root")).render(
  <RouterProvider router={router} />
);
