/* eslint-disable jsx-a11y/anchor-is-valid */
import { Breadcrumb, Button, Layout, Typography } from "antd";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppHeader from "../components/AppHeader/AppHeader";
import useAuth from "../hooks/useAuth";
import getAuth from "../functions/getAuth";
import rootUrl from "../rootUrl";

const HyderabadPharmaStopProcess = () => {
  const navigate = useNavigate();
  const authCheck = useAuth();
  const { rxid } = useParams();

  useEffect(() => {
    if (!authCheck()) {
      navigate("/login");
    }
  }, [authCheck, navigate]);

  const stopProcess = async () => {
    const res = await getAuth(`/api/hyd_pharma_stop_process/${rxid}`);
    console.log(res.status);
    if (res.status === 200) {
      alert("Processing Stopped for " + rxid);
      navigate("/hyd/hyd-pharma/history");
    } else {
      alert("ERROR");
    }
  };

  return (
    <>
      <Layout style={{ minHeight: "100vh" }}>
        <AppHeader />
        <Layout.Content style={{ padding: "0 48px", minHeight: "100vh" }}>
          <Breadcrumb style={{ margin: "16px 0" }}>
            <Breadcrumb.Item>
              <a href="/" onClick={() => window.location.assign("/")}>
                Home
              </a>
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => {}}>
              <a href="/hyd">Hyderabad Team</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => {}}>
              <a href="/hyd/hyd-pharma">Hyderabad Pharma</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => {}}>
              <a href="#" style={{ color: "black" }}>
                Upload
              </a>
            </Breadcrumb.Item>
          </Breadcrumb>

          <Typography.Title>Confirmation</Typography.Title>
          <Typography.Paragraph>
            Are you sure you want to stop processing the Request ID{" "}
            <span style={{ fontWeight: "bold", padding: 5 }}>{rxid}</span>? This
            action is permanent and cannot be reversed. The processing of this
            file will be stopped and its status will be to ERROR.
          </Typography.Paragraph>

          <div style={{ width: 500, display: "flex", flexDirection: "row" }}>
            <Button
              style={{
                marginRight: 10,
                color: "white",
                backgroundColor: "red",
              }}
              onClick={stopProcess}
            >
              Yes, I am sure
            </Button>
            <Button>Cancel</Button>
          </div>
        </Layout.Content>
      </Layout>
    </>
  );
};

export default HyderabadPharmaStopProcess;
