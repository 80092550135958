/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  ArrowLeftOutlined,
  FileExcelOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import {
  Button,
  Spin,
  Table,
  Tag,
  Typography,
  Breadcrumb,
  Layout,
  Modal,
} from "antd";
import Link from "antd/es/typography/Link";
import React, { useEffect, useState } from "react";
import getToken from "../functions/getToken";
import rootUrl from "../rootUrl";
import { useNavigate, useParams } from "react-router-dom";
import AppHeader from "../components/AppHeader/AppHeader";
import { saveAs } from "file-saver";

const postAuth = (spath, data) =>
  fetch(`${rootUrl}${spath}`, {
    mode: "cors",
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  });

const ShowQuotes = ({ goBack, data }) => {
  return (
    <div>
      <Button icon={<ArrowLeftOutlined />} onClick={goBack}>
        Back
      </Button>
      <Typography.Title level={4}>Quotes</Typography.Title>
      <ul
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        {data?.data?.quotes?.map((x) => (
          <li key={x}>{x}</li>
        ))}
      </ul>
      <Typography.Title level={4}>Spokespeople</Typography.Title>
      <ul
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        {data?.data?.spokespeople?.map((x) => (
          <li key={x}>{x}</li>
        ))}
      </ul>
    </div>
  );
};

const ShowOtherDetails = ({ goBack, data }) => {
  return (
    <div>
      <Button icon={<ArrowLeftOutlined />} onClick={goBack}>
        Back
      </Button>
      <div style={{ marginTop: 10 }}>
        <table
          style={{
            backgroundColor: "rgba(0,0,0,0.1)",
            borderRadius: 10,
            padding: 10,
            width: 800,
          }}
        >
          <tr style={{ width: 400 }}>
            <td style={{ fontWeight: "bold" }}>Main Story Driver</td>
            <td>{data?.topics?.main_story_driver}</td>
          </tr>
          <tr style={{ width: 400 }}>
            <td style={{ fontWeight: "bold" }}>Topic Codes</td>
            <td>
              {data?.topics?.topic_codes.map((x) => x.toString()).join(", ")}
            </td>
          </tr>
          <tr style={{ width: 400 }}>
            <td style={{ fontWeight: "bold" }}>Topic Messages</td>
            <td>
              {data?.topics?.topic_names.map((x) => x.toString()).join(", ")}
            </td>
          </tr>
          <tr style={{ width: 400 }}>
            <td style={{ fontWeight: "bold" }}>Messages Found</td>
            <td>
              {data?.messages?.message_codes
                .map((x) => x.toString())
                .join(", ")}
            </td>
          </tr>
        </table>
      </div>
      {/* <div>{JSON.stringify(data)}</div> */}
    </div>
  );
};

const ShowCriteriaDetails = ({ goBack, data, onForcePass, onForceFail }) => {
  const cdx = data?.data?.criteria_details;
  return (
    <div
      style={{
        margin: "15px",
        padding: "10px",
        borderRadius: "5px",
        backgroundColor: "#F2F8FC",
      }}
    >
      <div>
        <Button
          style={{
            marginBottom: "15px",
            backgroundColor: "#007BFF",
            color: "white",
            marginRight: 10,
          }}
          icon={<ArrowLeftOutlined />}
          onClick={goBack}
        >
          Back
        </Button>
        {data?.data?.status === "PASSING" ? (
          <Button
            style={{ marginRight: 10 }}
            onClick={() => onForceFail(data?.item_id)}
          >
            Force Fail
          </Button>
        ) : (
          <Button
            style={{ marginRight: 10 }}
            onClick={() => onForcePass(data?.item_id)}
          >
            Force Pass
          </Button>
        )}
      </div>
      <table
        style={{ border: "1px solid black", width: "100%", textAlign: "left" }}
      >
        {Object.keys(cdx)?.map((key) => (
          <tr style={{ borderBottom: "1px solid grey" }}>
            <td style={{ padding: "10px" }}>{key}</td>
            <td style={{ padding: "10px" }}>
              {cdx[key] === "PASSING" ? (
                <Tag
                  color="green"
                  style={{ cursor: "pointer" }}
                  onClick={() => {}}
                >
                  PASSING
                </Tag>
              ) : (
                <Tag
                  color="red"
                  style={{ cursor: "pointer" }}
                  onClick={() => {}}
                >
                  FAILING
                </Tag>
              )}
            </td>
          </tr>
        ))}
      </table>
    </div>
  );
};

const ShowMetadata = ({ data, goBack }) => {
  const [activeItem, setActiveItem] = useState("Headline");
  const items = [
    "Headline",
    "Byline/Commentator",
    "Overall Tone",
    "Client Mentions",
    "Summary",
  ];
  return (
    <div>
      <Button icon={<ArrowLeftOutlined />} onClick={goBack}>
        Back
      </Button>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {items.map((x) => (
          <div
            onClick={() => {
              setActiveItem(x);
            }}
            style={{
              marginRight: 10,
              backgroundColor: activeItem === x ? "rgba(0,0,250,1)" : "white",
              color: activeItem === x ? "white" : "black",
              padding: 5,
              borderRadius: 5,
              cursor: "pointer",
            }}
          >
            {x}
          </div>
        ))}
      </div>
      <div
        style={{
          backgroundColor: "rgba(0,0,0,0.1)",
          padding: 10,
          borderRadius: 10,
        }}
      >
        {activeItem === "Headline" ? (
          <div>{JSON.stringify(data?.headline)}</div>
        ) : null}
        {activeItem === "Byline/Commentator" ? <div>{data?.byline}</div> : null}
        {activeItem === "Overall Tone" ? (
          <div>{data?.data.sentiment}</div>
        ) : null}
        {activeItem === "Client Mentions" ? (
          <div>
            {data?.data?.client_mentions?.map((i, n) => (
              <div>
                {n + 1} : {i}
              </div>
            ))}
          </div>
        ) : null}
        {activeItem === "Summary" ? <div>{data?.data?.summary}</div> : null}
      </div>
    </div>
  );
};

const BangaloreDetails = () => {
  const { detailId } = useParams();
  const [mode, setMode] = useState("standard");
  const [target, setTarget] = useState(null);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (mode === "standard") setTarget(null);
  }, [mode]);

  useEffect(() => {
    if (detailId) {
      setMode("standard");
      setIsLoading(true);
      postAuth("/api/blr_get_request_by_id", { request_id: detailId })
        .then(async (r) => {
          const rt = await r.json();
          console.log(rt);
          setData(rt);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [detailId]);
  const colnames = [
    "Index",
    "Item Id",
    "Headline",
    "Byline",
    "PDF Link",
    "Metadata",
    "Sentiment",
    "Other Details",
    "Quotes",
    "Reject Criteria",
  ];
  let aidata = data?.ai_analysis_data?.data;

  const formattedAIData = aidata
    ? aidata
        ?.filter((i) => i)
        .map((i, n) => ({
          Index: n + 1,
          "Item Id": i?.item_id,
          Headline: i?.headline,
          Byline: i?.byline,
          "PDF Link": (
            <Link
              onClick={(e) => {
                e.preventDefault();
                window.open(i?.pdflink, "_blank");
              }}
              href={i?.pdflink}
            >
              Open
              <LinkOutlined style={{ marginLeft: 10 }} />
            </Link>
          ),
          Metadata: (
            <Button
              onClick={() => {
                setTarget(i);
                setMetadataModalOpen(true);
              }}
            >
              View
            </Button>
          ),
          Sentiment: (
            <Tag
              color={
                i?.data?.sentiment === "POSITIVE"
                  ? "green"
                  : i?.data?.sentiment === "NEGATIVE"
                  ? "red"
                  : "yellow"
              }
            >
              {i?.data?.sentiment}
            </Tag>
          ),
          "Other Details": (
            <Button
              onClick={() => {
                setTarget(i);
                setOtherDetailsModalOpen(true);
              }}
            >
              View
            </Button>
          ),
          Quotes: (
            <Button
              onClick={() => {
                setTarget(i);
                setQuotesModalOpen(true);
              }}
            >
              View
            </Button>
          ),
          "Reject Criteria": (
            <Tag
              color={
                i?.data?.status?.toString().toLowerCase() === "passing"
                  ? "green"
                  : "red"
              }
              onClick={() => {
                setTarget(i);
                setRejectCriteriaModalOpen(true);
              }}
              style={{ cursor: "pointer" }}
            >
              {i?.data?.status}
            </Tag>
          ),
        }))
    : [];

  const nav = useNavigate();

  const [metadataModalOpen, setMetadataModalOpen] = useState(false);
  const [otherDetailsModalOpen, setOtherDetailsModalOpen] = useState(false);
  const [quotesModalOpen, setQuotesModalOpen] = useState(false);
  const [rejectCriteriaModalOpen, setRejectCriteriaModalOpen] = useState(false);

  const forceRefresh = () => {};
  if (isLoading)
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin size="large" />
        <Typography.Text style={{ marginTop: 15, color: "royalblue" }}>
          Loading..
        </Typography.Text>
      </div>
    );

  return (
    <Layout>
      <AppHeader />
      <Layout.Content style={{ padding: "0 48px", height: 1000 }}>
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item onClick={() => nav("/")}>
            <a href="/" onClick={(e) => e.preventDefault()}>
              Home
            </a>
          </Breadcrumb.Item>
          <Breadcrumb.Item onClick={() => {}}>
            <a href="/blr">Bengaluru Team</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item onClick={() => {}}>
            <a href="/blr/isentia">Isentia</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item onClick={() => {}}>
            <a href={"/blr/isentia/" + detailId} style={{ color: "black" }}>
              {detailId}
            </a>
          </Breadcrumb.Item>
        </Breadcrumb>
        <div style={{ width: "auto" }}>
          <>
            {data?.ai_analysis_status === "Not Started" ? (
              <div>
                <div style={{ marginBottom: 10, color: "black" }}>
                  Extraction not started yet
                </div>
              </div>
            ) : null}
            {data?.ai_analysis_status === "Processing" ? (
              <div>
                <div style={{ marginBottom: 10, color: "royalblue" }}>
                  Data is still processing, please wait..
                </div>
                <Spin size="large" />
              </div>
            ) : null}
            {data?.ai_analysis_status === "Completed" ? (
              <div style={{ height: 800, overflow: "auto" }}>
                <Typography.Title>Details for {detailId}</Typography.Title>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 10,
                  }}
                >
                  <Typography.Text>
                    Time Taken: {parseInt(data.ai_analysis_data.time_taken)}{" "}
                    seconds
                  </Typography.Text>
                  <Button
                    icon={<FileExcelOutlined />}
                    onClick={() => {
                      alert(
                        "Download will start in a couple of minutes. Do not close this window."
                      );
                      postAuth("/api/blr_download_output", {
                        request_id: detailId,
                      })
                        .then(async (r) => {
                          const blob = await r.blob();
                          saveAs(blob, `output_${detailId}.xlsx`);

                          if (r.status === 200) {
                            alert("Download OK");
                          }
                        })
                        .catch((e) => alert(e.toString()));
                    }}
                  >
                    Download Output
                  </Button>
                </div>
                {isLoading ? (
                  <Spin size="large" />
                ) : (
                  <Table
                    pagination={false}
                    dataSource={formattedAIData}
                    columns={colnames.map((x) => ({
                      dataIndex: x,
                      key: x,
                      label: x,
                      title: x,
                      ...(x === "Headline" ? { width: 300 } : {}),
                    }))}
                  />
                )}
              </div>
            ) : null}
          </>
          <Modal
            open={metadataModalOpen}
            onCancel={() => setMetadataModalOpen(false)}
            onOk={() => setMetadataModalOpen(false)}
          >
            <ShowMetadata
              data={target}
              goBack={() => setMetadataModalOpen(false)}
            />
          </Modal>
          <Modal
            open={quotesModalOpen}
            onCancel={() => setQuotesModalOpen(false)}
            onOk={() => setQuotesModalOpen(false)}
          >
            <ShowQuotes
              data={target}
              goBack={() => setQuotesModalOpen(false)}
            />
          </Modal>
          <Modal
            width={900}
            open={otherDetailsModalOpen}
            onCancel={() => setOtherDetailsModalOpen(false)}
            onOk={() => setOtherDetailsModalOpen(false)}
          >
            <ShowOtherDetails
              data={target}
              goBack={() => setOtherDetailsModalOpen(false)}
            />
          </Modal>
          <Modal
            open={rejectCriteriaModalOpen}
            onCancel={() => setRejectCriteriaModalOpen(false)}
            onOk={() => setRejectCriteriaModalOpen(false)}
          >
            <ShowCriteriaDetails
              requestId={detailId}
              data={target}
              goBack={() => setMode("standard")}
              forceRefresh={forceRefresh}
              onForcePass={async (item_id) => {
                let cnf = window.confirm(
                  `You are about to FORCE PASS item ID for ${item_id} in batch ID ${detailId}. This action is irreversible. Are you sure you want to continue?`
                );
                if (cnf) {
                  let resp = await postAuth("/api/blr_force_pass", {
                    request_id: detailId,
                    item_id,
                  });
                  if (resp.status === 200) {
                    alert("Force Pass OK");
                    forceRefresh();
                  } else {
                    alert("ERROR " + resp.status + (await resp.text()));
                  }
                }
              }}
              onForceFail={async (item_id) => {
                let cnf = window.confirm(
                  `You are about to FORCE FAIL item ID ${item_id} in batch ID ${detailId}. This action is irreversible. Are you sure you want to continue?`
                );
                if (cnf) {
                  let resp = await postAuth("/api/blr_force_fail", {
                    request_id: detailId,
                    item_id,
                  });
                  if (resp.status === 200) {
                    alert("FORCE FAIL OK");
                    forceRefresh();
                  } else {
                    alert("ERROR " + resp.status + (await resp.text()));
                  }
                }
              }}
            />
          </Modal>
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default BangaloreDetails;
